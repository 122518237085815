<template>
  <a-card class="my-2 rounded text-left">
    <p class="text-left pbz-font subtitle-md-medium">
      Edit {{ $t('sideBar.formula_variable') }}
    </p>
    <a-form-model
      ref="formData"
      novalidate
      :model="form"
      @submit.prevent="saveFormulaVariable"
    >
      <fieldset :disabled="loadingData || loadingEdit">
        <label class="mb-1">
          {{ $t('price_setting.label_variable') }} <sup class="text-danger">*</sup>
        </label>
        <a-form-model-item
          prop="symbol"
          class="mb-3"
          :rules="[
            {
              required: true,
              message: $t('price_setting.error_variable_name'),
            },
            {
              pattern: /^[0-9a-zA-Z_]*$/,
              message: $t('price_setting.error_variable_name_pattern'),
            },
            {
              max: 30,
              message: $t('price_setting.error_max_variable_name'),
            }
          ]"
        >
          <a-input
            v-model="form.symbol"
            class="bg-light text-dark"
            size="large"
            disabled
          />
        </a-form-model-item>

        <label class="mb-1">
          {{ $t('price_setting.label_description') }}
        </label>
        <a-form-model-item
          prop="name"
          class="mb-3"
          :rules="[
            {
              max: 30,
              message: $t('price_setting.error_description'),
            }
          ]"
        >
          <a-input
            v-model="form.name"
            size="large"
            allow-clear
            :disabled="loadingData || loadingEdit"
          />
        </a-form-model-item>
        <div class="text-right mt-3">
          <a-button
            type="link"
            size="large"
            class="text-primary font-weight-bold mr-2"
            :disabled="loadingEdit"
            @click="$router.push({ path: '/formula-variable', query: $route.query })"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            v-if="permission.includes('WRITE')"
            type="primary"
            size="large"
            class="font-weight-bold"
            :disabled="loadingData"
            :loading="loadingEdit"
            html-type="submit"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </fieldset>
    </a-form-model>
  </a-card>
</template>

<script>
import { getVariableDetail, crudPriceVariable } from '@/api/price'

export default {
  props: ['permission'],
  data() {
    return {
      form: {
        symbol: '',
        name: '',
      },
      loadingData: true,
      loadingEdit: false,
      name: '',
      symbol: '',
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      await getVariableDetail({
        id: this.$route.params.id,
        params: { business_id: this.$route.query.business_id },
      })
      .then(({ data: response }) => {
        const { name, symbol } = response?.data || {}
        this.form = { name, symbol }
        this.name = name
        this.symbol = symbol
      })
      .catch((err) => {
        if (err.code !== 'ERR_CANCELED') {
          const { message } = err?.response?.data || {}
          this.$message.error(message || err?.message || 'Failed get detail Formula Variable')
        }
      })
      .finally(() => this.loadingData = false)
    },
    saveFormulaVariable() {
      this.$refs.formData.validate(async valid => {
        const { id } = this.$route.params
        if (valid && id) {
          const query = this.$route.query
          const isOk = () => {
            this.$message.success('Formula Variable has been saved')
            this.$router.push({ path: '/formula-variable', query })
          }
          this.loadingEdit = true
          if (this.name !== this.form.name || this.symbol !== this.form.symbol) {
            await crudPriceVariable({
              method: 'put',
              id,
              data: {
                business_id: query.business_id,
                category: 'FORMULA',
                ...this.form,
              },
            })
            .then(isOk)
            .catch((err) => {
              const { message } = err?.response?.data || {}
              this.$message.error(message || 'Failed to save Formula Variable')
            })
            .finally(() => { this.loadingEdit = false })
          } else {
            setTimeout(isOk, 500)
          }
        }
      })
    },
  },
}
</script>
